import axios from "axios";

const instance = axios.create({});

// instance.defaults.headers.common["Content-Type"] = "application/json";
//instance.defaults.headers.common["Accept"] = "application/json";
// instance.defaults.withCredentials = true;

// instance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     return error;
//   }
// );

export default instance;


export const multipart_instance = axios.create({});

// multipart_instance.defaults.headers.common["Accept"] = "multipart/form-data";
// multipart_instance.defaults.headers.common["Content-Type"] = "multipart/form-data";
// multipart_instance.defaults.withCredentials = true;

// multipart_instance.defaults.headers.common["Authorization"] =
//   "Bearer " + sess_json?.auth_token;

// multipart_instance.interceptors.response.use(
//   (response) => {
//     localStorage.removeItem("serverError");
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 401) {
//       localStorage.clear();
//       localStorage.setItem(
//         "serverError",
//         JSON.stringify({
//           heading: "Session Timed Out",
//           text: "Your Session has expired. Please re-login to continue.",
//         })
//       );
//       window.location.href = "/server-error";
//     }

//     if (error.response.status === 404) {
//       localStorage.clear();
//       localStorage.setItem(
//         "serverError",
//         JSON.stringify({
//           heading: "API Failed",
//           text: "Your Session has expired. Please re-login to continue.",
//         })
//       );
//       window.location.href = "/server-error";
//     }

//     if (error.response.status === 500 || error.response.status === 429) {
//       localStorage.clear();
//       localStorage.setItem(
//         "serverError",
//         JSON.stringify({
//           heading: "Server ERROR",
//           text: "Your Session has expired. Please re-login to continue.",
//         })
//       );
//       window.location.href = "/server-error";
//     }

//     return error;
//   }
// );

