import { Formik, FormikProvider, Field, useFormik, useField } from "formik";
import { Button, Container, Form, Table } from "react-bootstrap";
import { initialValues, validationSchema } from "./Util";
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { uploadSimilarImages, similarmatch, getSimilarImageResult } from "../../redux/AllThunk/SimilarImages";
import Thumb2 from "./Thumb2";
import logo from "../../assets/images/logo_white.png";
import loader from "../../assets/svg/loader.svg";
import MatchResult from "./MatchResult";
import { Link } from "react-router-dom";

const formKey = "similar_images";
const resultKey = "similar_image_result";

const Similarimages = (props) => {

  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);

  const [batch_no, setBatch_no] = useState("");
  const [fetchCount, setFetchCount] = useState(0);
  const [result, setResult] = useState({});
  const [imgPath, setImgPath] = useState("");

  const multifileRef = useRef();

  const loaderStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  };

  const submitHandler = async (values) => {
    if (formik.isValid === true) {
      let formData = new FormData();

      if (values.multipleFiles.length > 0) {
        // Object.keys(values.multipleFiles).map((key) => {
        //   console.log("key", key, values.multipleFiles)
        //   formData.append(`files[${key}]`, values.multipleFiles[key])
        // });
        formData.append(`contents`, values.multipleFiles[0])

        //           let data = new FormData();
        //  data.append('contents', fs.createReadStream('///wsl.localhost/Ubuntu/home/ainocular/code/python_test/fabs/0Q0861D003699S624.jpg'));

        // props.dispatch(
        //   uploadSimilarImages({
        //     payload: formData,
        //     key: formKey,
        //   })
        // );
        setLoading(true); // Set loading to true when the request starts
        try {
          await props.dispatch(
            similarmatch({
              payload: formData,
              key: formKey,
            })
          );
        } catch (error) {
          // Handle error if needed
        } finally {
          setLoading(false); // Set loading to false when the request finishes
        }


      } else {
        formik.setFieldError("multipleFiles", "Please select files");
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {

    if (props.formResult.success === true) {
      setResult(props.formResult.message);
      /*  setBatch_no(props.formResult.message.path);
       
       setTimeout(() => {
         formik.resetForm();
 
         //fetchResult(props.formResult.message.path);
         setTimeout(() => {
           setFetchCount(1);
           props.dispatch({
             type: "RESET_FORM",
             key: formKey,
           });
         }, 5000);
       }, 5000); */
    }
  }, [props?.formResult]);

  /*   const fetchResult = (batchNo)=>{
  
      const batchnumber =batchNo?batchNo:batch_no
      //const batchnumber = "batch_6";
      console.log('batchnumber',batchnumber);
      props.dispatch(
        getSimilarImageResult({
          payload:{batchnumber},
          key: resultKey,
        })
      );
    } */

  /*   useEffect(()=>{
      props?.imagesResult?.data.data !==undefined && setResult(props?.imagesResult?.data.data);
      props?.imagesResult?.data.imgUrl!==undefined && setImgPath(props?.imagesResult?.data.imgUrl);
    },[props?.imagesResult?.data]); */

  // useEffect(()=>{
  //   console.log('fetchcount',fetchCount);
  //   setTimeout(() => {
  //     if(fetchCount>0 && fetchCount<5 && Object.keys(result).length==0){
  //       fetchResult();

  //       setTimeout(()=>{
  //         setFetchCount(fetchCount+1);
  //       },10000)
  //     }
  //   }, 30000);
  // },[fetchCount])
  //console.log('re',result);

  return (
    <div>
      {/* <div className="d-flex flex-row px-5 py-4" style={{ background: "#002231", color: "white" }}>
        <img src={logo} style={{ widht: "3rem", height: "3rem" }} />
        <p className="m-auto fs-4">Similar Image Search</p>
      </div> */}

      <div className="container-fluid px-5 py-3" style={{ background: "#002231", color: "white" }}>
        <div className="row">
          <div className="col-sm-4">
            <img src={logo} style={{ widht: "3rem", height: "3rem" }} />
          </div>
          <div className="col-sm-4">
            <p className="fs-4 m-0">Similar Image Search</p>
          </div>
          <div className="col-sm"></div>
        </div>
      </div>


      <Container fluid className="mt-5">

        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <div className="col-4 d-flex">
              <Form.Control
                type="file"
                autoComplete="off"
                isInvalid={!!formik.errors.filename && !!formik.touched.filename}
                ref={multifileRef}
                onChange={(e) => {
                  formik.setFieldValue("multipleFiles", e.target.files);
                  setFile(URL.createObjectURL(e.target.files[0]));
                  setResult({});
                }}
                accept=".jpg,.jpeg,.png"
                multiple
                className="me-1"
              />
              {formik.errors.multipleFiles && (
                <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                  {formik.errors.multipleFiles}
                </Form.Control.Feedback>
              )}

              <Button className="button" style={{ backgroundColor: "#09819b" }} type="submit">
                Submit
              </Button>
            </div>
            {file.length > 0 && (
              <div className="col-2 m-2">
                <img src={file} style={{ width: '12rem', height: '12rem' }} />
              </div>
            )}
            {/* <div className="col-2 text-center">
              <Button className="button" variant="success" type="button" onClick={()=>fetchResult()}>
                Fetch Result
              </Button> 
            </div> */}
          </Form>
        </FormikProvider>

        <div className="mt-2">

          {loading && (
            <div style={loaderStyles}>
              <img src={loader} alt="Loading..." />
            </div>
          )}

          {Object.keys(result).length > 0 && (

            <MatchResult result={result} />
          )}
        </div>
      </Container>
    </div>
  );
};

export default connect((state) => {
  return {
    formResult: { ...state?.Forms?.[formKey] },
    //imagesResult: {...state?.GetData?.[resultKey] }
  };
})(Similarimages);
