import { multipart_instance as axios } from "../Interceptor";
import { api_url, HTTP_OK, similarMatchAPI } from "../../Constants";
// import axios from "axios";

export const uploadSimilarImages = (action) => {

  return async (dispatch) => {
    await axios({
      url: api_url + "similarmatch",
      method: "post",
      data: action.payload,
      // headers:{'Access-Control-Allow-Origin':'*','Access-Control-Allow-Headers':'Access-Control-Allow-Headers'},
      headers: {
        'Content-Type': 'multipart/form-data', // Let Axios handle boundary
        'Content-Type': 'image/png', // Specific Content-Type for the image
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        "Accept": "multipart/form-data",

      }
    }).then(
      (response) => {
        //console.log('res>',response);
        if (response.status == HTTP_OK) {

          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.message,
          key: action.key,
        });
      }
    );

  };
};

export const similarmatch = (action) => {

  return async (dispatch) => {
    await axios({
      url: api_url + "similarmatch",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        console.log('res>', response);
        if (response.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.message,
          key: action.key,
        });
        console.log('ewrror>', error);
      }
    );

  };
};

export const cavmatchApi = (action) => {

  return (dispatch) => {
    axios({
      url: "http://164.52.220.216:8888/cavmatch",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.message,
          key: action.key,
        });
      }
    );

  };
};

export const getSimilarImageResult = (action) => {
  return (dispatch) => {
    axios({
      url: api_url + "getSimilarImageResult",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response.status == HTTP_OK) {
          dispatch({
            type: "GET_SUCCESS",
            //payload: response?.data.data,
            payload: { data: response?.data.data, imgUrl: response?.data.similar_img_path },
            key: action.key,
          });
        } else {
          dispatch({
            type: "GET_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GET_ERROR",
          payload: error?.message,
          key: action.key,
        });
      }
    );
  };
};

export const getBatchList = (action) => {
  return (dispatch) => {
    axios({
      url: api_url + "getSimilarImageBatches",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response.status == HTTP_OK) {
          dispatch({
            type: "GET_SUCCESS",
            payload: response?.data.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "GET_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GET_ERROR",
          payload: error?.message,
          key: action.key,
        });
      }
    );
  };
}

export const getMoodMatches = (action) => {

  return async (dispatch) => {
    await axios({
      url: api_url + "getMoodMatches",
      method: "post",
      data: action.payload,
      headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers' }
    }).then(
      (response) => {
        //console.log('res>',response);
        if (response.status == HTTP_OK) {

          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.message,
          key: action.key,
        });
      }
    );

  };
};

